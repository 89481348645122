<template>
  <div>
    <heade currentrStr="result"></heade>
    <div class="result common-width">
      <el-table
        :data="tableData"
        style="width: 100%"
        max-height="500"
        border
        stripe
        class="center-table"
      >
        <el-table-column
          prop="project"
          label="参赛项目"
          width="250"
          :resizable="false"
        >
        </el-table-column>
        <el-table-column prop="stage" label="阶段" :resizable="false">
        </el-table-column>
        <el-table-column prop="team" label="队名" :resizable="false">
        </el-table-column>
        <el-table-column prop="testperiod" label="时间" :resizable="false">
        </el-table-column>
        <el-table-column prop="school" label="学校" :resizable="false">
        </el-table-column>
        <el-table-column prop="award" label="获奖" :resizable="false">
        </el-table-column>
        <el-table-column
          label="操作"
          width="320"
          fixed="right"
          :resizable="false"
        >
          <template slot-scope="scope">
            <el-button
              type="success"
              @click.native.prevent="to(tableData[scope.$index]._id, tableData)"
              plain
              size="small"
              v-if="scope.row.award!= ''"
            >
              获奖证书
            </el-button>
            <el-button
              v-if="scope.row.levelurl != ''"
              type="primary"
              plain
              @click.native.prevent="toNext(scope.row.levelurl)"
              size="small"
            >
              等级证书
            </el-button>

            <el-popover placement="bottom" width="200" trigger="click">
              <!-- <ul>
                <li
                  v-for="(item, index) in scope.row.tutors"
                  :key="index"
                  style="border-bottom: 1px solid #ccc"
                  @click="terFun(item,scope.row._id)"
                >
                  {{ item }}
                </li>
              </ul> -->
              <el-tag
                v-for="(item, index) in scope.row.tutors"
                @click="terFun(item, scope.row._id)"
                :key="index"
                style="margin-left:5px;cursor: pointer;"
                >{{item}}</el-tag
              >
              <el-button
                slot="reference"
                size="small"
                style="margin-left: 10px"
                type="primary"
                v-if="scope.row.tutors.length != 0"
                >导师证书</el-button
              >
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import heade from "@/components/header.vue";

export default {
  data() {
    return {
      tableData: [],
    };
  },
  components: {
    heade,
  },
  mounted() {
    this.initResult();
  },
  methods: {
    toNext(url) {
      let data = {
        op: "checkuser",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/signup.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            if (document.body.clientWidth > 1000) {
              window.open(url);
            } else {
              window.location.href = url;
            }
            //  window.location.href=url;
          } else {
            _this.$message.info("请先完善个人信息！");
            setTimeout(() => {
              _this.$router.push({
                path: "./selfDetail",
              });
            }, 1000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // this.$router.push({ path: url });
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    initResult() {
      let data = {
        op: "mylist",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/scores.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.tableData = response.data.data;
          } else if (response.data.value == "sessionerror") {
            // 未登录
            _this.$message.info(response.data.msg);
            setTimeout(() => {
              _this.$router.push({
                path: "./login-reg-pass",
                query: { url: "./index" },
              });
            }, 800);
          } else {
            _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    terFun(name, _id) {
      let data = {
        op: "getcert",
        id: _id,
        name: name,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/scores.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            if (document.body.clientWidth > 1000) {
              window.open(response.data.url);
            } else {
              window.location.href = response.data.url;
            }

            // window.open(response.data.url);
          } else {
            _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    to(_id) {
      let data = {
        op: "getcert",
        id: _id,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/scores.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            if (document.body.clientWidth > 1000) {
              window.open(response.data.url);
            } else {
              window.location.href = response.data.url;
            }

            // window.open(response.data.url);
          } else {
            _this.$message.info(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style  scoped>
.result {
  /* max-width:1240px; */
  margin: 50px auto !important;
  padding: 0 15px;
  /* border: 1px solid #ededed; */
}
/* /deep/ .ant-table-wrapper {
  max-height: 500px;
  overflow: auto;
} */
/* /deep/ .ant-table-wrapper {
  border: 1px solid #ededed;
} */

</style>
<style> 
/* .center-table th {
  text-align: center !important;
  font-weight: bold !important;
} */
</style>